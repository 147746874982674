<template>
  <div v-if="details">
    <b-card v-if="details.customer">
      <b-row>
        <b-col
          cols="12"
        >
          <h2 class="mb-2 text-primary">
            {{ $t('customer_details') }}
          </h2>
          <hr class="mb-2">
        </b-col>
        <b-col
          v-if="details.customer"
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('name') }}
          </h6>
          <p>
            {{ details.customer.name }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('phone') }}
          </h6>
          <p>
            {{ details.customer.mobile }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('email') }}
          </h6>
          <p>
            {{ details.customer.email }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('member_no') }}
          </h6>
          <p>
            {{ details.customer.member_no }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="details.real_estate">
      <b-row>
        <b-col
          cols="12"
        >
          <h2 class="mb-2 text-primary">
            {{ $t('real-estate-details') }}
          </h2>
          <hr class="mb-2">
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('title') }}
          </h6>
          <p>
            {{ details.real_estate.title }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('ad_number') }}
          </h6>
          <p>
            {{ details.real_estate.ad_number }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('advertiser_email') }}
          </h6>
          <p>
            {{ details.real_estate.advertiser_email }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('advertiser_name') }}
          </h6>
          <p>
            {{ details.real_estate.advertiser_name }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('advertiser_phone') }}
          </h6>
          <p>
            {{ details.real_estate.advertiser_phone }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="mb-2 text-primary">
              {{ $t('conflict_details') }}
            </h2>
            <div
              class="d-flex"
              style="gap:12px"
            >
              <b-button
                v-if="details.status !== 'closed'"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="changeStatus()"
              >
                {{ $t('close_conflict') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-info"
                @click="showReplyModal()"
              >
                {{ $t('replay') }}
              </b-button>
            </div>
          </div>
          <hr class="mb-2">
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('ad_number') }}
          </h6>
          <p>
            {{ details.ad_number }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('ad_title') }}
          </h6>
          <p>
            {{ details.ad_title }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('name') }}
          </h6>
          <p>
            {{ details.name }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('email') }}
          </h6>
          <p>
            {{ details.email }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('phone') }}
          </h6>
          <p>
            {{ details.phone }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('created_at') }}
          </h6>
          <p>
            {{ formatDate(details.created_at) }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('status_value') }}
          </h6>
          <p>
            {{ details.status_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="12"
          lg="12"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('details') }}
          </h6>
          <p>
            {{ details.details }}
          </p>
          <hr>
        </b-col>
        <b-col
          v-if="details.documents.length"
          md="12"
          lg="12"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('documents') }}
          </h6>
          <b-row>
            <b-col
              v-for="(item, index) in details.documents"
              :key="index"
              md="4"
              cols="12"
            >
              <img
                :src="item"
                class="img-fluid mb-2"
                style="max-height: 400px; object-fit: cover; width:100%"
                alt=""
              >
            </b-col>
          </b-row>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="details.replies.length">
      <b-row>
        <b-col
          cols="12"
        >
          <h2 class="mb-2 text-primary">
            {{ $t('replies_details') }}
          </h2>
          <hr class="mb-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('creator') }}
          </h6>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('creator_type') }}
          </h6>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('created_at') }}
          </h6>
        </b-col>
        <b-col>
          <h6 class="custom-line-clamp-one">
            {{ $t('reply_message') }}
          </h6>
        </b-col>
      </b-row>
      <hr>
      <div
        v-for="(reply, index) in details.replies"
        :key="index"
      >
        <b-row>
          <b-col
            md="4"
            lg="3"
          >
            <p>
              {{ reply.creator }}
            </p>
          </b-col>
          <b-col
            md="4"
            lg="3"
          >
            <p>
              {{ reply.creator_type }}
            </p>
          </b-col>
          <b-col
            md="4"
            lg="3"
          >
            <p>
              {{ formatDate(reply.created_at) }}
            </p>
          </b-col>
          <b-col>
            <p>
              {{ reply.message }}
            </p>
          </b-col>
        </b-row>
        <hr>
      </div>
    </b-card>
    <b-modal
      id="reply-modal"
      ref="reply-modal"
      ok-variant="info"
      modal-class="modal-info"
      centered
      :title="$t('reply_conflict')"
    >
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('reply_message')"
            vid="message"
            rules="required"
          >
            <b-form-group
              :label="$t('reply_message')"
            >
              <b-form-textarea
                v-model="message"
                :placeholder="$t('reply_message')"
                autocomplete="off"
                rows="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          :disabled="loading"
          variant="info"
          @click="onSubmit"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      details: null,
      message: null,
      loading: false,
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.axios.get(`/conflict/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
        })
    },
    showReplyModal() {
      this.$refs['reply-modal'].show()
    },
    changeStatus() {
      this.axios.patch(`/conflict/${this.$route.params.resourceId}/change-status`, {
        status: 'closed',
      })
        .then(response => {
          this.getDetails()
          this.$bvToast.toast(response.data.message, {
            title: this.$t('Success'),
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
            appendToast: true,
          }, 500)
        })
    },
    onSubmit() {
      this.loading = true
      const route = `/conflict/${this.$route.params.resourceId}/reply`
      this.$refs.form.validate()
        .then(success => {
          if (success) {
            this.axios.patch(route, {
              message: this.message,
            })
              .then(response => {
                this.$bvToast.toast(response.data.message, {
                  title: this.$t('general.success'),
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 3000,
                  appendToast: true,
                })
                this.$refs['reply-modal'].hide()
                this.loading = false
                this.message = null
                this.getDetails()
              })
              .catch(error => {
                this.$refs['reply-modal'].hide()
                this.message = null
                this.$bvToast.toast(error.response.data.message, {
                  title: this.$t('Error'),
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 3000,
                  appendToast: true,
                })
                this.loading = false
              })
          }
        })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
  },
}
</script>
